// import '../../../firebase/config';
import { useContext, useEffect, useRef, useState } from "react";
import dynamic from "next/dynamic";
import Image from "next/legacy/image";
import { useRouter } from "next/router";
import applogo from "@public/logo_wo_bg.webp";
import styles from "../styles/nav.module.css";
import useMediaQuery from "@mui/material/useMediaQuery";
import { trackEvent } from "@utils/analytics/event-analytics";
import "react-phone-number-input/style.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import {
  ClickAwayListener,
  Divider,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Switch,
} from "@mui/material";
import { WEB_ENGAGE, UTSAV_ADMINS, LANGUAGE } from "@utils/constants";
import Link from "next/link";
import Typography from "@mui/material/Typography";
import { Store } from "@utils/store/ContextStore";
import LoggedInUserIcon from "../../../public/assets/icons/loggedin_user.svg";
import UserProfileIcon from "../../../public/assets/icons/Navbar/mi_user.svg";
import PujaIcon from "../../../public/assets/icons/Navbar/pray.svg";
import SettingsIcon from "../../../public/assets/icons/Navbar/carbon_settings.svg";
import LogoutIcon from "../../../public/assets/icons/Navbar/fe_logout.svg";
import { logout } from "src/onboardingModule/queries/auth.queries";
import { completeLogout } from "@utils/auth/logout";
import theme from "../styles/theme";
import { ThemeProvider } from "styled-components";
import Cookies from "js-cookie";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";
import { NoSsr } from "@mui/core";
import { useDispatch, useSelector } from "react-redux";
import { deleteUserData } from "@utils/reduxToolkit/features/userSlice";
import { styled } from "@mui/styles";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import WarningModal from "./warningModal";

const LangugageDropdown = dynamic(() => import("./languageDropdown"), {
  ssr: false,
});

const Onboarding1 = dynamic(
  () => import("src/onboardingModule/components/onboardingBigScreen"),
  {
    ssr: false,
  }
);

const UtsavModal = dynamic(
  () => import("src/globalModule/components/UtsavModal"),
  {
    ssr: false,
  }
);

const WelcomeModal = dynamic(
  () => import("../../onboardingModule/components/welcomeModal"),
  {
    ssr: false,
  }
);

// const HomeUIcon = dynamic(
//   () => import('@public/assets/icons/home_outline.svg'),
//   {
//     ssr: false
//   }
// );

// const useStyles = makeStyles({
//   paper: {
//     height: "100vh", // Adjust this value as needed
//   },
// });

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 2,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& .MuiSwitch-thumb": {
        backgroundColor: "#1877F2", // Thumb color when checked
      },
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#E9F2FF" : "#E9F2FF",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#FF9800",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: "#FF9800",
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 17,
    height: 17,
    backgroundColor: "#FF9800", // Thumb color when unchecked
  },
  "& .MuiSwitch-track": {
    border: "1px solid #E9E9EA",
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#FFF5E5",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

export default function Navbar({ toHome = false, query }) {
  const { t } = useTranslation();
  const router = useRouter();
  const smallScreen = useMediaQuery("(max-width:700px)");
  const { state } = useContext(Store);
  const loggedInUser = state.user;
  // console.log("loggedInUser", loggedInUser);

  const [scrollDown, setScrollDown] = useState(false);
  const [open, setOpen] = useState(false);
  const [isLoginModalOpen, setLoginModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const anchorRef = useRef(null);
  const prevOpen = useRef(open);

  const [prevScrollY, setPrevScrollY] = useState(0);
  const [openLogoutModal, setOpenLogoutModal] = useState(false);
  // const [swipeNavbarState, setSwipeNavbarState] = useState({ left: false });
  const [reset, setReset] = useState(false);

  const dispatch = useDispatch();

  // const { user } = useSelector((state) => state.userDetails);

  /**Error - cannot destructure property user of (0, react_redux__WEBPACK_IMPORTED_MODULE as it is undefined */
  const userState = useSelector((state) => state.userDetails);
  const user = userState ? userState.user : null;
  /**Error - cannot destructure property user of (0, react_redux__WEBPACK_IMPORTED_MODULE as it is undefined */

  // const user = null;
  //const classes = useStyles();
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      if (currentScrollY > prevScrollY) {
        setScrollDown(true);
      } else {
        setScrollDown(false);
      }

      setPrevScrollY(currentScrollY);
    };

    window.addEventListener("scroll", handleScroll);
    let newUserFlag = Cookies.get("newUser");
    if (newUserFlag) {
      setIsModalOpen(true);
      Cookies.remove("newUser");
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  //console.log("isModalOpen", isModalOpen);

  // useEffect(() => {
  //   setIsModalOpen(router.query.newUser === "true");
  // }, [router.query]);

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return () => {};
    }

    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  const toggleLogoutModal = async () => {
    setOpenLogoutModal((cur) => !cur);
  };

  const handleLogout = async (setProcessing) => {
    logout(loggedInUser.accessToken);
    completeLogout(setProcessing);
    dispatch(deleteUserData());
  };

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openLoginModal = () => {
    setLoginModalOpen(true);
    setReset(false);
  };

  const closeLoginModal = () => {
    setLoginModalOpen(false);
    setReset(true);
    //setIsModalOpen(true);
  };

  const goToSignUp = () => {
    window.location.hash = "";
    window.location.hash = "#sec5";
  };

  const [redirectedModal, setRedirectedModal] = useState({
    open: false,
    heading: "",
    message: "",
    acceptMethod: () => {},
    icon: null,
  });

  const handleClick = () => {
    // let url = router.pathname;

    setRedirectedModal({
      open: true,
      heading: t("you-will-be-taken-to-home"),
      message: t("are-you-sure"),
      acceptMethod: () => {
        setRedirectedModal((prevState) => ({
          ...prevState,
          open: false,
        }));
        router.push(`/${router.locale}/puja`);
      },

      icon: (
        <div
          style={{
            backgroundColor: "#FFF5E6",
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            padding: "15px",
          }}
        >
          <div
            style={{
              backgroundColor: "#FEE4E2",
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              padding: "5px",
            }}
          >
            <DeleteOutlineIcon sx={{ fontSize: "2rem", color: "#FF5757" }} />
          </div>
        </div>
      ),
    });

    // switch (url) {
    //   case "/puja/registration/review":
    //   case "/puja/sub/[id]":
    //   case "/puja/[id]/[eventid]/all-pujas":
    //   case "/puja/[id]/[eventid]/[subeventid]":
    //     router.push(`/puja/${query?.id}/${query?.eventid}`);
    //     break;

    //   default:
    //     router.push("/home");
    //     break;
    // }
  };

  const handleLanguageChange = (e) => {
    const { eventid, subeventid, id } = router.query;
    let currentPathname = router.pathname;
    currentPathname = currentPathname.replace("/[id]", `/${id}`);
    currentPathname = currentPathname.replace("/[eventid]", `/${eventid}`);

    let lang = e.target.checked ? LANGUAGE.ENGLISH : LANGUAGE.HINDI;
    // if (e.target.checked) {
    //   router.push(currentPathname, undefined, {
    //     locale: LANGUAGE.ENGLISH,
    //   });
    // } else {
    //   router.push(currentPathname, undefined, {
    //     locale: LANGUAGE.HINDI,
    //   });
    // }
    // Get the current URL's search part (query parameters)
    const searchParams = new URLSearchParams(window.location.search);

    // Check if there are any query parameters
    if (searchParams.toString()) {
      // Append the query parameters to the currentPathname
      currentPathname += `?${searchParams.toString()}`;
    }

    sessionStorage.setItem("locale", lang);
    window.location.href = `/${lang}${currentPathname}`;
  };

  return (
    <>
      {toHome && (
        <div
          className={`${styles.navHead} ${
            scrollDown ? styles.boxShadow : styles.boxShadowNone
          }`}
          id="navbar"
        >
          <div className={styles.navHeaderContainer}>
            <div
              className={styles.headerContainer}
              onClick={(e) => {
                e.preventDefault();
                handleClick();
              }}
            >
              <div className={`${styles.nav_logo}`}>
                <Image
                  src={applogo}
                  alt={t("utsav-logo")}
                  priority
                  objectFit="cover"
                  className={styles.nav_logo_img}
                />
              </div>
              <div className={styles.utsavHeading}>
                <Typography className={styles.logoText}>
                  {t("utsav")}
                </Typography>
              </div>
            </div>
            {smallScreen && (
              <div className={styles.languageContainer}>
                <Typography
                  sx={{
                    color: theme.palette.text.primaryHead,
                    fontWeight: "bold",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  हिं
                </Typography>
                {/* <LangugageDropdown /> */}
                <IOSSwitch
                  inputProps={{ "aria-label": "Switch demo" }}
                  defaultChecked={
                    router.locale === LANGUAGE.ENGLISH ? true : false
                  }
                  onChange={handleLanguageChange}
                />
                <Typography
                  sx={{
                    color: theme.palette.text.primaryHead,
                    fontWeight: "bold",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  En
                </Typography>
              </div>
            )}
          </div>
          {!smallScreen && (
            <>
              <LangugageDropdown />
            </>
          )}
        </div>
      )}

      {!toHome && (
        <ThemeProvider theme={theme}>
          <div className={`${styles.root} ${styles.visibleNavbar}`} id="root">
            <div
              className={`${styles.nav} ${scrollDown ? styles.boxShadow : ""}`}
              id="navbar"
            >
              <div
                className={styles.navHeaderContainer}
                onClick={(e) => {
                  e.preventDefault();
                  handleClick();
                }}
              >
                <div className={`${styles.nav_logo}`}>
                  <Image
                    src={applogo}
                    alt={t("utsav-logo")}
                    priority
                    objectFit="cover"
                    className={styles.nav_logo_img}
                  />
                </div>
                <div className={styles.utsavHeading}>
                  <Typography className={styles.logoText}>
                    {t("utsav")}
                  </Typography>
                </div>
              </div>
              <ul className={`${styles.nav_menu_list2}`}>
                <Link href="/home">
                  <li id="home">
                    <Typography
                      fontFamily={"Poppins"}
                      sx={
                        router.pathname === "/home"
                          ? theme.typography.linkTextHighlighted
                          : theme.typography.linkText
                      }
                    >
                      {t("home")}
                    </Typography>
                  </li>
                </Link>
                <Link href="/puja">
                  <li id="puja">
                    <Typography
                      sx={
                        router.pathname === "/puja"
                          ? theme.typography.linkTextHighlighted
                          : theme.typography.linkText
                      }
                    >
                      {t("pujas")}
                    </Typography>
                  </li>
                </Link>
                <Link href="/blogs">
                  <li id="blogs">
                    <Typography
                      sx={
                        router.pathname === "/blogs"
                          ? theme.typography.linkTextHighlighted
                          : theme.typography.linkText
                      }
                    >
                      {t("blogs")}
                    </Typography>
                  </li>
                </Link>
                <Link href="/community">
                  <li id="community">
                    <Typography
                      sx={
                        router.pathname === "/community"
                          ? theme.typography.linkTextHighlighted
                          : theme.typography.linkText
                      }
                    >
                      {t("community")}
                    </Typography>
                  </li>
                </Link>
              </ul>
              <ul className={`${styles.nav_menu_list}`}>
                <li>
                  <LangugageDropdown />
                </li>
                <li>
                  <div className={styles.loggedInUser}>
                    {loggedInUser.name === "guest" ||
                    loggedInUser.accessToken === null ? (
                      <div
                        onClick={openLoginModal}
                        className={styles.loginLink}
                      >
                        <p className={styles.navListItem}>{t("login")}</p>
                      </div>
                    ) : (
                      <div
                        ref={anchorRef}
                        onClick={handleToggle}
                        aria-controls={open ? "menu-list-grow" : undefined}
                        aria-haspopup="true"
                        className={styles.loggedInUserDetails}
                      >
                        {loggedInUser?.dp ? (
                          <Image
                            src={loggedInUser.dp || ""}
                            alt={t("dp")}
                            height={30}
                            width={30}
                            className={styles.loggedInUserDp}
                          />
                        ) : (
                          <LoggedInUserIcon />
                        )}
                        <span className={styles.loggedInUserName}>
                          {loggedInUser.name !== "guest" && loggedInUser.name}
                        </span>
                        <NoSsr>
                          <Popper
                            open={open}
                            anchorEl={anchorRef.current}
                            role={undefined}
                            transition
                            disablePortal
                            style={{ zIndex: "99" }}
                          >
                            {({ TransitionProps, placement }) => (
                              <Grow
                                {...TransitionProps}
                                style={{
                                  transformOrigin:
                                    placement === "bottom" && "center bottom",
                                }}
                              >
                                <Paper>
                                  <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList
                                      id="menu-list-grow"
                                      onKeyDown={handleListKeyDown}
                                    >
                                      <Link
                                        scroll={false}
                                        href={`/profile/${loggedInUser.uid}`}
                                        passHref
                                      >
                                        <MenuItem>
                                          <UserProfileIcon />
                                          <Typography
                                            className={`${styles.menuText}  ${
                                              router.pathname ===
                                              `/profile/[...params]`
                                                ? styles.activeItem
                                                : ""
                                            }`}
                                          >
                                            {t("user-profile")}
                                          </Typography>
                                        </MenuItem>
                                      </Link>

                                      <Link
                                        scroll={false}
                                        href={"/your-puja"}
                                        passHref
                                      >
                                        <MenuItem>
                                          <PujaIcon />
                                          <Typography
                                            className={`${styles.menuText}  ${
                                              router.pathname === `/your-puja`
                                                ? styles.activeItem
                                                : ""
                                            }`}
                                          >
                                            {t("your-pujas")}
                                          </Typography>
                                        </MenuItem>
                                      </Link>

                                      <Link
                                        scroll={false}
                                        href={`/settings`}
                                        passHref
                                        locale={router.locale}
                                        hrefLang={router.locale}
                                      >
                                        <MenuItem>
                                          <SettingsIcon />
                                          <Typography
                                            className={`${styles.menuText} ${
                                              router.pathname === `/settings`
                                                ? styles.activeItem
                                                : ""
                                            }`}
                                          >
                                            {t("settings")}
                                          </Typography>
                                        </MenuItem>
                                      </Link>
                                      {(user?.role ===
                                        UTSAV_ADMINS.UTSAV_ADMIN ||
                                        user?.role ===
                                          UTSAV_ADMINS.BLOG_POSTERS) && (
                                        <Link
                                          scroll={false}
                                          href={`/blogs/create`}
                                          passHref
                                          rel="alternate"
                                          hrefLang={router.locale}
                                        >
                                          <MenuItem>
                                            <AddIcon />
                                            <Typography
                                              className={`${styles.menuText}`}
                                            >
                                              {" "}
                                              {t("create-blog")}
                                            </Typography>
                                          </MenuItem>
                                        </Link>
                                      )}

                                      <MenuItem>
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            width: "100%",
                                          }}
                                        >
                                          <Divider
                                            sx={{ opacity: 0.25, mb: 1 }}
                                          />
                                          {/* <div>
                                            <hr
                                              className={styles.verticalLine}
                                            />
                                          </div> */}

                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "row",
                                            }}
                                            onClick={(e) => {
                                              e.preventDefault();
                                              toggleLogoutModal();
                                            }}
                                          >
                                            <LogoutIcon />
                                            <Typography
                                              className={styles.menuText}
                                              style={{ color: "red" }}
                                            >
                                              {t("logout")}
                                            </Typography>
                                          </div>
                                        </div>
                                      </MenuItem>
                                    </MenuList>
                                  </ClickAwayListener>
                                </Paper>
                              </Grow>
                            )}
                          </Popper>
                        </NoSsr>
                      </div>
                    )}
                  </div>

                  <Onboarding1
                    isLoginModalOpen={isLoginModalOpen}
                    handleClose={closeLoginModal}
                    reset={reset}
                  />
                </li>
                {(loggedInUser.name === "guest" ||
                  loggedInUser.accessToken === null) && (
                  <li
                    className={`${styles.nav_menu_list_item}`}
                    onClick={() => {
                      trackEvent(
                        `${WEB_ENGAGE.SCREEN_NAMES.LANDING_PAGE} ${WEB_ENGAGE.BUTTON_NAMES.NAV_APP_DOWNLOAD} ${WEB_ENGAGE.EVENT_NAMES.BUTTON_CLICKED}`,
                        {
                          [WEB_ENGAGE.EVENT_PROPERTIES.SCREEN_NAME]:
                            WEB_ENGAGE.SCREEN_NAMES.LANDING_PAGE,
                          [WEB_ENGAGE.EVENT_PROPERTIES.BUTTON_NAME]:
                            WEB_ENGAGE.BUTTON_NAMES.NAV_APP_DOWNLOAD,
                        }
                      );
                    }}
                  >
                    <Link
                      href="https://play.google.com/store/apps/details?id=com.applex.utsav"
                      className={styles.section1_download_link}
                      target="_blank"
                    >
                      <button
                        type="button"
                        className={`${styles.downloadBtn}`}
                        onClick={goToSignUp}
                      >
                        <Typography>{t("download-app")}</Typography>
                      </button>
                    </Link>
                  </li>
                )}
              </ul>
            </div>
            {/* )} */}

            <WelcomeModal open={isModalOpen} onClose={closeModal} />
            {openLogoutModal && (
              <UtsavModal
                open={openLogoutModal}
                toggleModal={toggleLogoutModal}
                heading={t("logout")}
                message={t("do-you-want-to-continue")}
                acceptMsg={t("logout")}
                onAccept={handleLogout}
                rejectMsg={t("cancel")}
                positive={false}
              />
            )}
          </div>
        </ThemeProvider>
      )}

      {redirectedModal.open && (
        <WarningModal
          open={redirectedModal.open}
          heading={redirectedModal.heading}
          message={redirectedModal.message}
          acceptMsg={t("go-home")}
          onAccept={redirectedModal.acceptMethod}
          rejectMsg={t("continue-puja")}
          onReject={() =>
            setRedirectedModal((prevState) => ({
              ...prevState,
              open: !prevState.open,
            }))
          }
          icon={redirectedModal.icon}
        />
      )}
    </>
  );
}
