import { encryptData, decryptData } from "./encryption";
// import { printInConsole } from "@utils/devtools/utils";

export function getUserDataFromCookie(cookieUserData) {
  if (cookieUserData) {
    const decryptedData = decryptData(cookieUserData);
    if (decryptedData) {
      const userData = JSON.parse(decryptedData);
      return userData;
    }
  }

  return null;
}

export function getEncryptedCookieUserData(response) {
  // console.log("enc fun res------", response);
  const data = response.data;
  const user = response.user;
  const userRole = response?.userRole;

  if (data && user) {
    const obj = {
      ...data,
      name: user.name,
      dp: user.dp,
      coverPic: user.coverPic,
      gender: user.gender,
      UserRole: userRole?.role || null,
    };

    const encryptedUserData = encryptData(JSON.stringify(obj));
    const encryptedUser = encryptData(
      JSON.stringify({ ...data, UserRole: userRole?.role || null })
    );

    return { encryptedUserData, encryptedUser };
  } else {
    return null;
  }
}

export const clearCookies = () => {
  const cookies = document.cookie.split("; ");
  for (let c = 0; c < cookies.length; c++) {
    const d = window.location.hostname.split(".");
    while (d.length > 0) {
      const cookieBase =
        encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) +
        "=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=" +
        d.join(".") +
        " ;path=";
      const p = location.pathname.split("/");
      document.cookie = cookieBase + "/";
      while (p.length > 0) {
        document.cookie = cookieBase + p.join("/");
        p.pop();
      }
      d.shift();
    }
  }
};
